// Primary language tags for those languages we support
// export const AVAILABLE_LOCALES = {
//   en: "English",
//   "zh-cn": "简体中文",
//   pt: "Portugês (Brasil)",
//   ja: "日本語",
//   es: "Español",
//   ru: "Pусский",
//   fr: "Français",
//   ko: "한국어",
//   de: "Deutsch",
//   "zh-tw": "繁體中文"
// };

/**
 * 現在対応中の言語は
 * ・日本語
 * ・英語
 * ・中国語（簡体字）
 * ・中国語（繁体字）
 */
export const AVAILABLE_LOCALES = {
  ja: "日本語",
  en: "English",
  "zh-cn": "简体中文",
  "zh-tw": "繁體中文"
};

// Map from alternative primary language tags to those we support
export const FALLBACK_LOCALES = {
  jp: "ja"
};

import React from "react";
import PropTypes from "prop-types";
import styles from "./SignInButton.scss";
import { Button } from "../input/Button";
import { ReactComponent as KeyIcon } from "../icons/Key.svg";

export function SignInButton({ mobile }) {
  return (
    <Button className={mobile ? styles.mobileSignIn : styles.SignInButton} thick preset="signin" as="a" href="/signin">
      <KeyIcon />
    </Button>
  );
}
SignInButton.propTypes = {
  mobile: PropTypes.bool
};
